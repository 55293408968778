import React from 'react';
import LandingPage from './components/LandingPage';

const App = () => {
  return (
    <div className='App'>
      <LandingPage />
    </div>
  );
};

export default App;
